<template>
  <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0">
    <div class="form-inputs--lg" v-if="successful && !noPrivacy">
      <h2 class="h2-mail">controlla la tua casella email</h2>
      <label class="label-mail">{{formPrivato.email}}</label>
      <h2 class="h2-mail">fai click sul link per accedere alla piattaforma</h2>
    </div>
    <div class="form-inputs--lg" v-else-if="successful && noPrivacy">
      <h2 class="h2-mail">Ti ringraziamo per il tuo interesse</h2>
      <h2 class="h2-mail">Verrai conttatto al più presto all'indirizzo email</h2>
      <label class="label-mail">{{formPrivato.email}}</label>
    </div>
    <div class="form-inputs--lg" v-else>
      <b-form @submit.prevent="submitPrivato" class="mb-3">
        <field-email
          fieldId="email"
          placeholder="Il tuo indirizzo email"
          :state="validateState($v.formPrivato.email)"
          v-model="$v.formPrivato.email.$model"
          :errorMessage="decodeError($v.formPrivato, 'email', this.formPrivato)"
        />
        <hr class="my-4"/>
        <div>
          <b-form-group class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="checkTermsPrivato"
              v-model="$v.formPrivato.checkTerms.$model"
            >
            <label class="custom-control-label" for="checkTermsPrivato">
              Ho preso visione, ho compreso e accetto i <a
                href="https://mfsolvency.it/termini-e-condizioni"
                target="_blank"
              >
              TERMINI e le CONDIZIONI del Portale
              </a>, dichiaro di essere maggiorenne e di accettare ai sensi degli artt. 1341 e 1342 c.c. gli articoli
              2. utilizzo del servizio, 3. livello di servizio, 5. modalità di erogazione ed esenzione
              o limitazione di responsabilità, 8. risoluzione del contratto, 11. norme finali e foro
              competente. CONSENSO OBBLIGATORIO (FUNZIONAMENTO SERVIZIO)
            </label>
          </b-form-group>
<!--          <label class="custom-control  custom-checkbox custom-control-label">-->
<!--            Ho preso visione dell'Informativa Privacy Regolamento (UE) 2016/679, e presto il mio consenso-->
<!--            al trattamento dei miei dati personali per le seguenti finalità:-->
<!--            <br/>-->
<!--            <br/>-->
<!--          </label>-->
          <b-form-group class="custom-control custom-checkbox" v-if="!noPrivacy">
            <input
              type="checkbox"
              class="custom-control-input"
              id="checkDataPrivato"
              v-model="$v.formPrivato.checkViewPrivacy.$model"
            >
            <label class="custom-control-label" for="checkDataPrivato">
              Ho preso visione, ho compreso e accetto l'<a
              href="https://mfsolvency.it/informativa-privacy/"
              target="_blank"
            >
              INFORMATIVA sul trattamento dei dati personali
            </a>. CONSENSO OBBLIGATORIO (FUNZIONAMENTO SERVIZIO)
            </label>
          </b-form-group>
          <b-form-group class="custom-control custom-checkbox" v-if="!noPrivacy">
            <input
              type="checkbox"
              class="custom-control-input"
              id="checkDataGroup"
              v-model="$v.formPrivato.checkMarketingGroup.$model"
            >
            <label class="custom-control-label" for="checkDataGroup">
              Ho preso visione e ho compreso l’INFORMATIVA sul trattamento dei dati personali: CONSENSO FACOLTATIVO (MARKETING)
              <br/>
              a. Presto il mio consenso per finalità di marketing  diretto, consistenti nell’invio
              di comunicazioni informative e promozionali riferite ai servizi del Portale MF SOLVENCY
              <br/>
              b. Presto il mio consenso per finalità di marketing diretto anche da parte delle società
              <a
                href="https://mfsolvency.it/informativa-contitolari"
                target="_blank"
              >
                Contitolari facenti parte e non
              </a>
              del gruppo Class, consistenti nell’invio di comunicazioni informative
              e promozionali riferite ai servizi e prodotti delle società medesime.
            </label>
          </b-form-group>
        </div>
        <div class="text-center pt-2">
          <button-loader
            content="Invia"
            :disabled="$v.formPrivato.$invalid"
            buttonStyle="success"
            buttonClass="btn-block btn-lg"
          />
        </div>
      </b-form>
      <p class="m-0 text-center">Sei già registrato?
        <b-link to="accedi">Accedi</b-link>
      </p>
      <p class="m-0 text-center">Hai dimenticato la password?
        <b-link to="password-recovery">Clicca qui</b-link>
      </p>
    </div>
  </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import sameAs from 'vuelidate/lib/validators/sameAs';
import email from 'vuelidate/lib/validators/email';
import lodash from 'lodash';
import {
  decodeFormFieldError,
  extractErrorMessage,
  isPresent,
  removeProp,
  serverError,
  validateFormFieldState,
} from '@/utils/validators';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');
const FieldEmail = () => import('@/components/fields/FieldEmail.vue');

export default {
  name: 'FormAccountRegister',
  components: {
    ButtonLoader, FieldEmail,
  },
  props: {
    noPrivacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let clientValidation;
    if (this.noPrivacy) {
      clientValidation = {
        formPrivato: {
          email: {
            required,
            email,
          },
          checkTerms: {
            sameAs: sameAs(() => true),
          },
        },
      };
    } else {
      clientValidation = {
        formPrivato: {
          email: {
            required,
            email,
          },
          checkTerms: {
            sameAs: sameAs(() => true),
          },
          checkViewPrivacy: {
            sameAs: sameAs(() => true),
          },
          checkMarketingGroup: {
          },
        },
      };
    }

    return {
      submitted: false,
      successful: false,
      formPrivato: {
        email: this.inviteEmail,
        checkTerms: false,
        checkViewPrivacy: false,
        checkMarketingGroup: false,
      },
      clientValidation,
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
  },
  mounted() {
    this.formPrivato.email = this.inviteEmail;
    this.formPrivato.confirmEmail = this.inviteEmail;
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    clearTaxCodeForm(e) {
      console.log('clearTaxCodeForm', e);
      this.$v.formPrivato.personTaxCode.$reset();
      if (this.formPrivato.serverErrors?.personTaxCode) delete this.formPrivato.serverErrors.personTaxCode;
      if (this.serverValidation.formPrivato?.personTaxCode) delete this.serverValidation.formPrivato.personTaxCode;
    },
    submitPrivato() {
      const user = {
        email: this.formPrivato.email,
        noPrivacy: this.noPrivacy,
        privacyAccepts: [
          {
            privacyCode: 'accept',
            settingDate: new Date(),
            status: this.formPrivato.checkTerms || false,
          },
          {
            privacyCode: 'view-privacy',
            settingDate: new Date(),
            status: this.formPrivato.checkViewPrivacy || false,
          },
          {
            privacyCode: 'marketing-group',
            settingDate: new Date(),
            status: this.formPrivato.checkMarketingGroup || false,
          },
        ],
      };
      console.log('user', user);
      this.clearServerErrors();
      if (this.$v.formPrivato.$invalid) return;
      this.submitted = true;
      this.$store.dispatch('auth/accountRegister', user).then(
        (response) => {
          this.message = response.message;
          this.successful = true;
          this.submitted = false;
          console.log('request send successfully');
        },
        (error) => {
          this.message = extractErrorMessage(error);
          const { field } = error;
          if (isPresent(field)) {
            const serverMessages = {
              serverErrors: {},
            };

            serverMessages.serverErrors[field] = this.message;

            // Merge the server errors into the data. This doesn't yet cause
            // any validation errors but does make the messages (and therefore state)
            // accessible to the template.
            lodash.merge(this.formPrivato, serverMessages);
            // From the serverMessage we should create a validation object which will
            // be merged with the client validation objects. It's at this point the
            // computed property changes and Vuelidate updates it's validation
            this.serverValidation = {
              formPrivato: {},
            };
            this.serverValidation.formPrivato[field] = {
              serverError: serverError(field, false),
            };
          }

          this.successful = false;
          this.submitted = false;
        },
      );
    },
  },
};
</script>

<style lang="scss">
.h2-mail  {
  font-size: 1.2rem;
  margin-top: 10px;
}
.label-mail {
  font-size: 2rem;
}

</style>
